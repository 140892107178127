import { Text } from "@chakra-ui/react";
import { chakra, ResponsiveValue } from "@chakra-ui/system";

import { SlackChannel } from "@bucketco/shared/slackConnectionAPI";

import SlackHashLogo from "@/common/assets/slack-hash-logo.svg?react";

const SlackHashIcon = chakra(SlackHashLogo);

type SlackNotificationStatusToggleProps = {
  slackChannel: SlackChannel;
  size:
    | ResponsiveValue<(string & object) | "sm" | "md" | "lg" | "xs" | "2xs">
    | undefined;
};

export function SlackNotificationStatus({
  slackChannel,
  size = "sm",
}: SlackNotificationStatusToggleProps) {
  return (
    <Text fontSize={size} fontWeight="normal">
      <SlackHashIcon
        boxSize={3}
        display="inline-block"
        verticalAlign="middle"
      />{" "}
      <Text as="span">Updates will be shared in</Text>{" "}
      <Text as="span" display="inline-block" fontWeight="semibold">
        {slackChannel.name}
      </Text>
    </Text>
  );
}
