import React from "react";
import { useWatch } from "react-hook-form";
import {
  RiAddLine,
  RiCheckboxCircleLine,
  RiProhibitedLine,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Flex,
  HStack,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";

import { EnvironmentListItemDTO } from "@bucketco/shared/environmentAPI";
import {
  alwaysTrueFilter,
  COMPANY_ID_CONTEXT_FIELD,
  MAX_ROLLOUT_THRESHOLD,
} from "@bucketco/shared/filter";
import {
  FlagRule,
  FlagVersion,
  FlagVersionTargeting,
} from "@bucketco/shared/flagAPI";

import { AndOrList } from "@/common/components/AndOrList";
import CardContainer from "@/common/components/CardContainer";
import FormSwitchButtonGroup from "@/common/components/Form/FormSwitchButtonGroup";
import MotionBox from "@/common/components/MotionBox";
import {
  RuleAdvancedDisplay,
  RuleAdvancedForm,
} from "@/common/components/Rule/RuleAdvancedForm";
import { RulesEstimation } from "@/common/components/Rule/RulesEstimation";
import {
  RuleSimpleDisplay,
  RuleSimpleForm,
} from "@/common/components/Rule/RuleSimpleForm";
import { EnvironmentProvider } from "@/common/hooks/useCurrentEnv";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";

type RulesFormProps = {
  baseName: string;
  environment: EnvironmentListItemDTO;
  fields: (FlagRule & { id: string })[];
  version?: number;
  append: (value: FlagRule) => void;
  remove: (index: number) => void;
};

export function RulesForm({
  baseName,
  environment,
  fields,
  version,
  append,
  remove,
}: RulesFormProps) {
  const {
    isEnabled: useSimplifiedTargeting,
    isLoading: isSimplifiedTargetingLoading,
  } = useFeature("simplified-targeting");
  const targeting: FlagVersionTargeting = useWatch({ name: baseName });
  const { targetingMode } = targeting;

  return (
    <EnvironmentProvider envId={environment.id}>
      <CardContainer gap={1}>
        <Flex direction="row" justify="space-between" pb={1} px={2}>
          <Flex align="center" direction="row" gap={2}>
            <EnvironmentDisplayName environment={environment} />
            <RulesEstimation
              environmentId={environment.id}
              targeting={targeting}
            />
          </Flex>
          <Flex align="center" direction="row" gap={2}>
            {version && (
              <Text
                as={Link}
                color="dimmed"
                fontSize="sm"
                textDecoration="underline"
                to={`versions?environment=${environment.id}`}
              >
                v{version}
              </Text>
            )}
            {useSimplifiedTargeting && (
              <FormSwitchButtonGroup
                buttons={[
                  { label: "No one", id: "none" },
                  { label: "Some", id: "some" },
                  { label: "Everyone", id: "everyone" },
                ]}
                name={`${baseName}.targetingMode`}
                rules={{
                  deps: [baseName],
                }}
              />
            )}
          </Flex>
        </Flex>
        {isSimplifiedTargetingLoading ? (
          <Spinner color="dimmed" mb={2} ml={4} size="sm" />
        ) : (
          <AnimatePresence initial={false}>
            {(!useSimplifiedTargeting || targetingMode === "some") && (
              <MotionBox
                animate={{ opacity: 1, height: "auto" }}
                display="flex"
                exit={{ opacity: 0, height: 0 }}
                flexDirection="column"
                gap={1}
                initial={{ opacity: 0, height: 0 }}
                transition={{ duration: "0.15", ease: "easeInOut" }}
              >
                <AndOrList
                  conjunction="or"
                  conjunctionProps={{ ml: 4, zIndex: 1 }}
                  direction="vertical"
                  gap={0}
                >
                  {useSimplifiedTargeting && (
                    <Card p={4} w="full">
                      <RuleSimpleForm baseName={baseName} />
                    </Card>
                  )}
                  {fields.map(({ id }, index) => (
                    <Card key={id} p={4} w="full">
                      <RuleAdvancedForm
                        baseName={`${baseName}.customRules.${index}`}
                        remove={() => remove(index)}
                      />
                    </Card>
                  ))}
                </AndOrList>
                <Button
                  alignSelf="flex-start"
                  color="dimmed"
                  leftIcon={<RiAddLine size={16} />}
                  size="sm"
                  variant="ghost"
                  onClick={() =>
                    append({
                      partialRolloutThreshold: MAX_ROLLOUT_THRESHOLD,
                      partialRolloutContextAttribute: COMPANY_ID_CONTEXT_FIELD,
                      filter: alwaysTrueFilter,
                    })
                  }
                >
                  Add rule
                </Button>
              </MotionBox>
            )}
          </AnimatePresence>
        )}
      </CardContainer>
    </EnvironmentProvider>
  );
}

const sharedCardProps = {
  p: 4,
  w: "full",
};

type Props = {
  targeting: FlagVersion;
};

export const RulesDisplay = ({ targeting }: Props) => {
  const { isEnabled: useSimplifiedTargeting } = useFeature(
    "simplified-targeting",
  );

  const {
    environment,
    currentVersion,
    targetingMode,
    segmentIds,
    companyIds,
    userIds,
    customRules,
  } = targeting;

  const hasSimpleTargeting =
    !!segmentIds.length || !!companyIds.length || !!userIds.length;
  const hasAdvancedTargeting = !!customRules.length;
  const bg = useColorModeValue("gray.25", "gray.900");
  const borderColor = useColorModeValue("transparent", "gray.800");
  const currentBg = useColorModeValue("brand.50", bg);
  const currentBorderColor = useColorModeValue(borderColor, "brand.800");

  return (
    <CardContainer
      backgroundColor={currentVersion ? currentBg : bg}
      borderColor={currentVersion ? currentBorderColor : borderColor}
    >
      <RulesEstimation
        environmentId={environment.id}
        ml={2}
        targeting={targeting}
      />
      {targetingMode === "everyone" ? (
        <Card {...sharedCardProps}>
          <HStack color="dimmed" spacing={1.5}>
            <RiCheckboxCircleLine />
            <Text as="span">Everyone has access</Text>
          </HStack>
        </Card>
      ) : targetingMode === "some" &&
        (hasSimpleTargeting || hasAdvancedTargeting) ? (
        <AndOrList
          conjunction="or"
          conjunctionProps={{ ml: 4, zIndex: 1 }}
          direction="vertical"
          gap={0}
        >
          {useSimplifiedTargeting && hasSimpleTargeting && (
            <Card {...sharedCardProps}>
              <RuleSimpleDisplay targeting={targeting} />
            </Card>
          )}
          {customRules.map((targetingRule, i) => (
            <Card key={i} {...sharedCardProps}>
              <RuleAdvancedDisplay targetingRule={targetingRule} />
            </Card>
          ))}
        </AndOrList>
      ) : (
        <Card {...sharedCardProps}>
          <HStack color="dimmed" spacing={1.5}>
            <RiProhibitedLine />
            <Text as="span">No one has access</Text>
          </HStack>
        </Card>
      )}
    </CardContainer>
  );
};
