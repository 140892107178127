import { useCallback } from "react";
import { Control, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { RiSettings4Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  EnvironmentDTO,
  EnvironmentListItemDTO,
} from "@bucketco/shared/environmentAPI";
import {
  CreateFlagVersionArgs,
  CreateNewFlagVersionsArgs,
  CreateNewFlagVersionsSchema,
  Flag,
} from "@bucketco/shared/flagAPI";
import { StageDTO } from "@bucketco/shared/stageAPI";
import { GlobalSettingsUrl } from "@bucketco/shared/urls";
import {
  EnvironmentRules,
  expandEnvironmentTargeting,
  mergeVersions,
} from "@bucketco/shared/utils/flagRule";

import { useAuthContext } from "@/auth/contexts/authContext";
import { ConfirmationDialog } from "@/common/components/ConfirmationDialog";
import FormReset from "@/common/components/Form/FormReset";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmit from "@/common/components/Form/FormSubmit";
import FormSwitchButtonGroup from "@/common/components/Form/FormSwitchButtonGroup";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { LoadingSpinner } from "@/common/components/LoadingSpinner";
import { RulesForm } from "@/common/components/Rule/RulesForm";
import { SlackNotificationStatus } from "@/common/components/SlackNotificationStatus";
import { useFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { useEnvironmentsData } from "@/environment/data/useEnvironmentsData";
import { useFlagData } from "@/flags/data/useFlagData";
import { useFlagVersionsCreateMutation } from "@/flags/data/useFlagVersionCreateMutation";
import { StageIcon } from "@/stage/components/StagesIcon";
import { useStagesData } from "@/stage/data/useStagesData";

type FlagEnvironmentFormProps = {
  baseName: `versions.${number}`;
  environment: EnvironmentListItemDTO;
  control: Control<CreateNewFlagVersionsArgs>;
  version?: number;
};

const FlagEnvironmentForm = ({
  baseName,
  environment,
  control,
  version,
}: FlagEnvironmentFormProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${baseName}.customRules`,
  });

  return (
    <RulesForm
      append={append}
      baseName={baseName}
      environment={environment}
      fields={fields}
      remove={remove}
      version={version}
    />
  );
};

type FlagFormProps = {
  environments: EnvironmentDTO[];
  stages: StageDTO[];
  flag: Flag;
};

const FlagForm = ({ environments, stages, flag }: FlagFormProps) => {
  const { currentEnv } = useAuthContext();
  const confirmSaveDisclosure = useDisclosure();
  const createFlagVersionsMutation = useFlagVersionsCreateMutation(
    flag.featureId,
    flag.id,
    flag.currentVersions.map((v) => v.id),
  );

  const expandVersions = useCallback(
    (versions: EnvironmentRules[]) =>
      expandEnvironmentTargeting(environments, versions),
    [environments],
  );

  const form = useForm<CreateNewFlagVersionsArgs>({
    resolver: zodResolver(CreateNewFlagVersionsSchema),
    values: {
      stageId: flag.stageId ?? stages[0]?.id,
      versions: expandVersions(flag.currentVersions),
      changeDescription: "",
    },
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: "versions",
  });

  const onSaveClick = form.handleSubmit(() => {
    confirmSaveDisclosure.onOpen();
  });

  const onConfirmClick = useFormMutationSubmitHandler(
    form,
    createFlagVersionsMutation,
    (versions, variables) => {
      form.reset({
        stageId: variables.stageId,
        versions: expandVersions(versions),
        changeDescription: "",
      });
      confirmSaveDisclosure.onClose();
    },
    {
      successToast: "Targeting rules updated",
      errorToast: "Failed to update targeting rules",
    },
  );

  const onStageClick = useCallback(
    (stageId: string, prevStageId: string | null) => {
      const stage = stages.find((s) => s.id === stageId);
      if (!stage) return;
      // Use the new stage's predefined environments
      let newVersions: CreateFlagVersionArgs[] = expandVersions(
        stage.stageEnvironments,
      );
      // Check if the previous stage exists
      const prevStage = stages.find((s) => s.id === prevStageId);
      if (prevStage) {
        // Get the current versions from the form values
        const currentVersions = form.getValues("versions");
        // Get the previous stage's predefined environments
        const prevStageVersions = expandVersions(prevStage.stageEnvironments);
        // Merge the added rules with the new stage's rules
        newVersions = mergeVersions(
          newVersions,
          prevStageVersions,
          currentVersions,
        );
      }
      form.setValue("versions", newVersions, { shouldDirty: true });
    },
    [expandVersions, form, stages],
  );

  return (
    <FormProvider {...form}>
      <Flex
        alignItems="flex-start"
        as="form"
        direction="column"
        gap={4}
        w="full"
        onSubmit={onSaveClick}
      >
        {!!stages.length && (
          <Flex alignItems="flex-end" flexWrap="wrap" gap={1} w="full">
            <FormSwitchButtonGroup
              _control={{ w: "auto" }}
              buttons={stages.map(({ id, name, colorIndex }) => ({
                id,
                label: name,
                leftIcon: <StageIcon boxSize={3} colorIndex={colorIndex} />,
              }))}
              flexWrap="wrap"
              label="Stage"
              name="stageId"
              rowGap={1}
              onChange={onStageClick}
            />
            <Button
              as={Link}
              color="dimmed"
              leftIcon={<RiSettings4Line size={16} />}
              size="sm"
              to={GlobalSettingsUrl(currentEnv!, "app-stages")}
              variant="ghost"
            >
              Manage stages
            </Button>
          </Flex>
        )}

        <Text fontWeight="medium">Rules</Text>
        {fields.map((field, index) => {
          const flagVersion = flag.currentVersions.find(
            (v) => v.environment.id === field.environmentId,
          );
          return (
            <FlagEnvironmentForm
              key={field.id}
              baseName={`versions.${index}`}
              control={form.control}
              environment={environments[index]}
              version={flagVersion?.version}
            />
          );
        })}
        <ButtonGroup>
          <FormSubmit>Save</FormSubmit>
          <FormReset>Reset</FormReset>
        </ButtonGroup>

        <ConfirmationDialog
          {...confirmSaveDisclosure}
          _contentProps={{ maxW: "2xl" }}
          description={
            <VStack spacing={4} w="full">
              <ManagedFormControl
                name="changeDescription"
                render={({ field }) => (
                  <Textarea
                    placeholder="Add a description of the changes..."
                    {...field}
                  />
                )}
              />
              <FormRootError />
            </VStack>
          }
          isLoading={createFlagVersionsMutation.isPending}
          title={
            <HStack spacing={4}>
              <Text whiteSpace="nowrap">Confirm rules update</Text>
              <Spacer />
              {flag.slackChannel && flag.slackNotificationsEnabled && (
                <SlackNotificationStatus
                  size="sm"
                  slackChannel={flag.slackChannel}
                />
              )}
            </HStack>
          }
          onCancel={() => {
            form.resetField("changeDescription");
            confirmSaveDisclosure.onClose();
          }}
          onConfirm={onConfirmClick}
        />
      </Flex>
    </FormProvider>
  );
};

export const FlagTargeting = ({ flagId }: { flagId: string }) => {
  const {
    data: flag,
    isLoading: isFlagLoading,
    isSuccess: isFlagSuccess,
  } = useFlagData(flagId);
  const { data: environments = [], isLoading: isEnvironmentsLoading } =
    useEnvironmentsData();
  const { data: stages = [], isLoading: isStagesLoading } = useStagesData();

  if (isEnvironmentsLoading || isFlagLoading || isStagesLoading) {
    return (
      <Center flexGrow={1}>
        <LoadingSpinner size="sm" />
      </Center>
    );
  }

  return (
    <Flex alignItems="flex-start" direction="column" gap={4} maxW="4xl">
      {isFlagSuccess ? (
        <FlagForm environments={environments} flag={flag} stages={stages} />
      ) : (
        <Text>Error loading flag</Text>
      )}
    </Flex>
  );
};
